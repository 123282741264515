<template>
  <div class="print_tag_wapper">
    <breadcrumb
      :title="$route.query.line || '排版方案'"
      childTitle="打印标签"
      @goBackPage="goBackPage"
    >
    </breadcrumb>
    <div class="com_content">
      <div class="tag_setTem_wap">
        <div class="tag_tem_wap">
          <p class="title">标签模板</p>
          <div class="tag_tem_list">
            <div
              @click="chooseTagTem(0)"
              :class="nowTagId == 0 ? 'active' : ''"
            >
              <span>默认模板（60×40）</span>
            </div>
            <!-- <div v-for="(item, index) in tagList" :key="index" @click="chooseTagTem(item.id)" :class="nowTagId == item.id?'active':''">
                        <span>{{item.name}}</span>
                    </div> -->
          </div>
        </div>
        <div class="tag_set">
          <p class="title">标签设置</p>
          <div>
            <p>
              <a-checkbox :checked="needLave" @change="changeLave"
                >生成余料标签</a-checkbox
              >
            </p>
            <p>
              <a-checkbox :checked="jxChecked" @change="changeJX"
                >标签图镜像</a-checkbox
              >
            </p>
            <p>
              <a-checkbox :checked="fiveSixDir" @change="showFiveSixDir"
                >五六面钻放板基准</a-checkbox
              >
            </p>
            <!-- <p><a-checkbox :checked="plankNumFed" @change="changePlankNumF">侧孔生成反面条码</a-checkbox></p> -->
          </div>

          <div class="tag_sort_check">
            <p>标签排序适应自动上下料：</p>
            <a-radio-group name="radioGroup" v-model="sortValue">
              <a-radio
                v-for="item in sortOption"
                :value="item.value"
                :key="item.value"
                >{{ item.name }}</a-radio
              >
            </a-radio-group>
          </div>
        </div>
      </div>
      <div class="tag_canvas_wap" id="tag_canvas_wap">
        <iframe
          width="100%"
          height="100%"
          src="https://tofr.thinkerx.com/index.html#!/index?factory_key=eggrj_17477"
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
import breadcrumb from '@/components/topBreadCrumb.vue'
import FileSaver from 'file-saver'
import JSZip from 'jszip'

import TagItem from '../../components/tag/tagItem.vue'
import TagItemLave from '../../components/tag/tagItemLave.vue'
import { JxTagData, countTagDataByDir } from '../../util/countTagData'
import defaultTagData from '../../util/defaultTagData'
import { exportPDF } from '../../util/exportFuncs'

export default {
  components: {
    breadcrumb,
    TagItem,
    TagItemLave,
  },
  data() {
    return {
      needLave: false,
      jxChecked: false,
      plankNumFed: false,
      showList: false,
      tagLoading: true,
      allPage: 0,
      spinning: false,
      canvasScaleXY: 2,
      fiveSixDir: false,
      sortOption: [
        {
          name: '默认',
          value: 1,
        },
        {
          name: '向上↑',
          value: 2,
        },
        {
          name: '向下↓',
          value: 3,
        },
      ],
      sortValue: 1,
      tagSetOption: [
        {
          name: '生成余料标签',
          value: 1,
        },
        {
          name: '标签图镜像',
          value: 2,
        },
        {
          name: '侧孔生成反面条码',
          value: 3,
        },
      ],
      tagSetForm: [],
      tagItemArr: [],
      recordLastTag: 0,
      tagIsLoaded: false,
      allCanvas: [],
      tagList: [],
      nowTagId: 0,
      nowTagTem: null,
      tagName: '',
      tagItemArrCopy: [],
      laveList: [],
      upTagItemArr: null,
      downTagItemArr: null,
      delayLoadTime: null, //延迟加载
      firstBigPlankLast: 0,
      wardrobeNum: 0, //柜体数量
      scrollTopValue: 0,
      isRecordSroll: false,
      labelImageFormat: 'jpg',
      fileSetting: [],
      img_hasDelimiter: false,
      stockNumLength: 3,
    }
  },
  watch: {
    nowTagId() {
      //
    },
    jxChecked(val) {
      switch (this.sortValue) {
        case 1:
          this.delayLoadingData(
            val
              ? [].concat(JxTagData(this.tagItemArrCopy))
              : [].concat(this.tagItemArrCopy)
          )
          break
        case 2:
          this.delayLoadingData(
            val
              ? [].concat(JxTagData(this.upTagItemArr.tagItemArr))
              : [].concat(this.upTagItemArr.tagItemArr)
          )
          break
        case 3:
          this.delayLoadingData(
            val
              ? [].concat(JxTagData(this.downTagItemArr.tagItemArr))
              : [].concat(this.downTagItemArr.tagItemArr)
          )
      }
    },
    sortValue(value) {
      let _trackEventValue = ''
      // 滚动条归0
      this.scrollTopValue = 0
      this.isRecordSroll = false
      if (value == 1) {
        if (this.jxChecked) {
          this.delayLoadingData([].concat(JxTagData(this.tagItemArrCopy)))
        } else {
          this.delayLoadingData([].concat(this.tagItemArrCopy))
          let lastParts =
            this.tagItemArrCopy[this.tagItemArrCopy.length - 1].parts
          this.recordLastTag = lastParts[lastParts.length - 1].plankNum
        }
        _trackEventValue = '默认'
      } else if (value == 2) {
        if (this.jxChecked) {
          this.delayLoadingData(
            [].concat(JxTagData(this.upTagItemArr.tagItemArr))
          )
        } else {
          this.delayLoadingData([].concat(this.upTagItemArr.tagItemArr))
        }
        this.recordLastTag = this.upTagItemArr.recordLastTag
        _trackEventValue = '向上'
      } else if (value == 3) {
        if (this.jxChecked) {
          this.delayLoadingData(
            [].concat(JxTagData(this.downTagItemArr.tagItemArr))
          )
        } else {
          this.delayLoadingData([].concat(this.downTagItemArr.tagItemArr))
        }
        this.recordLastTag = this.downTagItemArr.recordLastTag
        _trackEventValue = '向下'
      }
      this.$htm_trackEvent('标签适应上下料', 'click', _trackEventValue)
    },
  },
  methods: {
    deleteTem() {
      if (this.nowTagId) {
        this.$confirm('确定要删除该模板吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            this.$token(
              '/delete_tag_setting',
              {
                id: this.nowTagId,
              },
              (res) => {
                if (res.status == 1) {
                  let index = this.tagList.findIndex(
                    (item) => item.id == this.nowTagId
                  )
                  if (index) {
                    this.chooseTagTem(this.tagList[index - 1].id)
                  } else {
                    this.chooseTagTem(0)
                  }
                  this.tagList.splice(index, 1)
                  this.$message({
                    duration: 1000,
                    message: '删除成功',
                    type: 'success',
                  })
                }
              }
            )
          })
          .catch(() => {})
      } else {
        this.$message({
          duration: 1000,
          message: '默认模板不能删除',
          type: 'warning',
        })
      }
    },
    changeLave() {
      this.$htm_trackEvent('生成余料标签', 'click', this.needLave)
      this.needLave = !this.needLave
    },
    chooseTagTem(item) {
      if (item == this.nowTagId) return
      // this.jxChecked = false
      this.tagLoading = true
      localStorage.setItem('testNowTagId', item ? item : 0)
      if (item) {
        this.nowTagId = item
        this.getTagTemInfoById(item)
      } else {
        this.nowTagId = 0
        this.showList = true
        this.getTagTemInfoById(0)
        // setTimeout(() => {
        //     this.nowTagTem = null
        // },50)
      }
    },
    callBackTAGNum(data) {
      if (data == this.recordLastTag) {
        this.tagIsLoaded = true
      }
      if (data == this.firstBigPlankLast) {
        this.tagLoading = false
      }
    },
    goBackPage() {
      this.$router.push({
        path: '/newPaiban',
      })
    },
    changeTagSet() {},
    recodeScroll() {
      if (this.isRecordSroll) {
        this.scrollTopValue =
          document.getElementById('tag_canvas_wap').scrollTop
      }
    },
    showFiveSixDir() {
      this.fiveSixDir = !this.fiveSixDir
    },
    changeJX() {
      this.$htm_trackEvent('标签图镜像', 'click', this.jxChecked)
      this.isRecordSroll = false
      this.jxChecked = !this.jxChecked
    },
    changePlankNumF() {
      this.plankNumFed = !this.plankNumFed
    },
    print() {
      if (!this.tagIsLoaded) {
        this.$message.info({
          message: '标签加载中，请稍等',
          duration: 1000,
        })
        return
      }
      let urlArr = []
      let test = this.$refs.tagTem
      let width = test[0].mycanvas.width
      for (let i = 0; i < test.length; i++) {
        let pageData = test[i].mycanvas.toDataURL({
          multiplier: 4,
        })
        let data = {
          width: width / this.canvasScaleXY,
          url: pageData,
        }
        urlArr.push(data)
      }
      this.$htm_trackEvent('直接打印标签', 'click')
      if (typeof window.webToQt !== 'undefined') {
        let newUrlArr = []
        for (let i = 0; i < urlArr.length; ++i) {
          let newUrl = urlArr[i].url.replace(
            /^data:image\/(png|jpg|jpeg);base64,/,
            ''
          )
          newUrlArr.push(newUrl)
        }
        let printObj = {
          fileName: this.tagName + '.pdf',
          imgArr: newUrlArr,
          tagWidth: this.nowTagTem.tag_width,
          tagHeight: this.nowTagTem.tag_height,
        }
        webToQt.webLabelPdfToQtV2(printObj, 'print')
        return
      }
      this.$store.commit('savePrintData', urlArr)
      this.$router.push({
        path: '/print',
      })
    },
    getTagTemInfoById(id) {
      if (!id) {
        this.nowTagTem = JSON.parse(defaultTagData)
        this.showList = true
        return
      }
      this.$getByToken(
        '/load_tag_setting',
        {
          id,
          default: 1,
        },
        (res) => {
          this.nowTagTem = res.data.data
          this.showList = true
        }
      )
    },
    exportPdf() {
      if (!this.tagIsLoaded) {
        this.$message.info({
          message: '标签加载中，请稍等',
          duration: 1000,
        })
        return
      }
      this.spinning = true
      let tagTem = this.$refs.tagTem
      let canvas = document.getElementsByClassName('lower-canvas')
      let option = {
        name: this.tagName,
        scale: this.canvasScaleXY,
        tegTem: tagTem,
      }
      setTimeout(() => {
        exportPDF(option, canvas, (res) => {
          this.spinning = false
          if (res) {
            res.tagWidth = this.nowTagTem.tag_width
            res.tagHeight = this.nowTagTem.tag_height
            webToQt.webLabelPdfToQtV2(res, 'pdf')
          }
        })
      }, 50)
      this.$htm_trackEvent('导出标签pdf', 'click')
    },
    ecportImg() {
      if (!this.tagIsLoaded) {
        this.$message.info({
          message: '标签加载中，请稍等',
          duration: 1000,
        })
        return
      }
      let canvas
      if (!this.allCanvas.length) {
        canvas = document.getElementsByClassName('lower-canvas')
      } else {
        canvas = this.allCanvas
      }
      let images = []
      for (let i = 0; i < canvas.length; i++) {
        let dataUrl = canvas[i].toDataURL({
          width: canvas[i].width / this.canvasScaleXY,
          height: canvas[i].height / this.canvasScaleXY,
          left: 0,
          top: 0,
          format: this.labelImageFormat,
        })
        let item = {
          name: canvas[i].getAttribute('labelName'),
          url: dataUrl.replace(/^data:image\/(png|jpg|jpeg);base64,/, ''),
        }
        images.push(item)
      }
      let tagName = this.tagName + '标签图片'
      this.$htm_trackEvent('导出标图片', 'click')
      if (typeof window.webToQt !== 'undefined') {
        let imgArr = []
        for (let i = 0; i < images.length; ++i) {
          let obj = {
            url: images[i].url,
            fileName: images[i].name + '.jpg',
          }
          imgArr.push(obj)
        }
        let imgObj = {
          tagWidth: this.nowTagTem.tag_width,
          tagHeight: this.nowTagTem.tag_height,
          imgArr: imgArr,
          originFileName: tagName,
        }
        webToQt.webLabelImgToQt(imgObj)
        return
      }
      let zip = new JSZip()
      // let imgs = zip.folder(tagName)
      for (let k = 0; k < images.length; k++) {
        zip.file(images[k].name + `.${this.labelImageFormat}`, images[k].url, {
          base64: true,
        })
      }
      zip.generateAsync({ type: 'blob' }).then(function (content) {
        FileSaver.saveAs(content, tagName + '.zip')
      })
    },
    setTagTem(item) {
      this.tagLoading = true
      localStorage.setItem('testNowTagId', item ? item.id : 0)
      if (item) {
        this.nowTagId = item.id
        this.getTagTemInfoById(item.id)
        this.tagName = item.name
      } else {
        this.tagName = `标签60x40`
        this.nowTagId = 0
        this.getTagTemInfoById(0)
        this.showList = true
        // setTimeout(() => {
        //     this.nowTagTem = null
        // },50)
      }
    },
    async getTagList() {
      await this.$getByToken(
        '/get_tag_setting_list',
        { limit: 100, page: 1 },
        (res) => {
          if (res.status == 1 && res.data && res.data instanceof Array) {
            this.tagList = res.data
            this.nowTagId = this.$store.state.userInfo.custom_tag_id
            if (
              localStorage.testNowTagId &&
              localStorage.testNowTagId != 'undefined'
            ) {
              let tagId = JSON.parse(localStorage.testNowTagId)
              this.nowTagId = Number(tagId)
              if (Number(tagId)) {
                let nowTagInfo = res.data.filter(
                  (item) => item.id == Number(tagId)
                )
                if (nowTagInfo.length) {
                  this.setTagTem(nowTagInfo[0])
                } else {
                  this.setTagTem(0)
                }
              } else {
                this.setTagTem(0)
              }
            } else {
              this.setTagTem(0)
            }
          } else {
            this.tagList = []
            this.setTagTem(0)
          }
        }
      )
    },
    editTem() {
      if (!this.nowTagId) {
        this.$message({
          message: '默认标签不可编辑',
        })
        return
      }
      this.$htm_trackEvent('编辑标签模板', 'click')
      this.$router.push({
        path: '/editTagTem',
        query: {
          id: this.nowTagId,
        },
      })
    },
    addTem() {
      this.$htm_trackEvent('添加标签模板', 'click')
      this.$router.push({
        path: '/editTagTem',
      })
    },
    // 延迟加载部分数据，加载完成才能做后续操作
    delayLoadingData(arrs) {
      clearTimeout(this.delayLoadTime)
      // 记录第一块大板最后一块小板
      this.firstBigPlankLast = arrs[0].parts[arrs[0].parts.length - 1].plankNum
      this.tagLoading = false
      this.tagItemArr = arrs
      // 暂时处理xy互换情况下第二张大板镜像错误问题
      // if(arrs.length > 1){
      //     let len = arrs.length;
      //     this.tagItemArr = [arrs[0]]
      //     for(let i = 1; i < len; i++){
      //         this.delayLoadTime = setTimeout( () => {
      //             this.tagItemArr = this.tagItemArr.concat(arrs.slice(i, i + 2))
      //             if(i == (len - 1)){
      //                 this.tagLoading = false
      //                 document.getElementById('tag_canvas_wap').scrollTop = this.scrollTopValue
      //                 this.isRecordSroll = true
      //             }
      //         }, i * 200)
      //     }
      //     // this.delayLoadTime = setTimeout( () => {
      //     //     this.tagItemArr = this.tagItemArr.concat(arrs.splice(1))
      //     // },1000)
      // }else{
      //     this.tagLoading = false
      //     this.tagItemArr = arrs;
      // }
    },
    cancelLavelList(supplusParts, item) {
      let supplusValue = {
        texture: item.texture,
        orderNo: supplusParts[0].orderNo,
        roomName: supplusParts[0].roomName,
        matCode: supplusParts[0].matCode,
        parts: supplusParts,
        thick: supplusParts[0].thick,
        plankHeight: item.plankHeight,
        plankWidth: item.plankWidth,
        // plankSize: item.plankWidth + '*' + item.plankHeight + 'mm'
      }
      if (supplusParts[0].specialType == 'supplus') {
        let path = supplusParts[0].path[0]
        let xArr = []
        let yArr = []
        for (let i = 0; i < path.length; i++) {
          if (xArr.indexOf(path[i].x) < 0) {
            xArr.push(path[i].x)
          }
          if (yArr.indexOf(path[i].y) < 0) {
            yArr.push(path[i].y)
          }
        }
        if (xArr.length == yArr.length) {
          // 矩形
          if (xArr.length == 2) {
            supplusValue.plankSize =
              String(Math.max.apply(null, xArr)) +
              '*' +
              String(Math.max.apply(null, yArr))
          } else {
            //L形
            let min_width = xArr.filter((item) => {
              return (
                item > Math.min.apply(null, xArr) &&
                item < Math.max.apply(null, xArr)
              )
            })[0]
            let min_height = yArr.filter((item) => {
              return (
                item > Math.min.apply(null, yArr) &&
                item < Math.max.apply(null, yArr)
              )
            })[0]
            let max_width =
              Math.max.apply(null, xArr) - Math.min.apply(null, xArr)
            let max_height =
              Math.max.apply(null, yArr) - Math.min.apply(null, yArr)
            supplusValue.plankSize = `${max_width}(${min_width})*${max_height}(${min_height})`
          }
        } else {
          // 矩形
          supplusValue.plankSize =
            String(Math.max.apply(null, xArr)) +
            '*' +
            String(Math.max.apply(null, yArr))
        }
      } else {
        supplusValue.plankSize = ''
      }
      return supplusValue
    },
    async getFileSetting() {
      // 获取nc设置
      let process_setting_id = this.$store.state.ncSetting.process_setting_id
      await this.$token(
        '/get_nc_file_settings',
        {
          process_setting_id: process_setting_id ? process_setting_id : -1,
          uid: this.$store.state.userInfo.id,
        },
        (res) => {
          if (res.status == 1) {
            let setting = res.data
            let label_dict = setting.label_dict
            let nc_file_settings = setting.nc_file_settings
            let headerIds = nc_file_settings.customizeSetting.headerIds
            this.img_hasDelimiter = nc_file_settings.hasDelimiter
            this.stockNumLength = nc_file_settings.stockNumLength
            let ids = []
            let templateString =
              nc_file_settings.customizeSetting.templateString
            if (templateString) {
              templateString = templateString.split('_').map((item) => {
                return item.match(/\{([^)]*)\}/)[1]
              })
            } else {
              templateString = []
            }
            templateString.forEach((item) => {
              ids.push(headerIds[item])
            })
            for (let i = 0; i < ids.length; i++) {
              let item = label_dict[String(ids[i])]
              let lable = ''
              let value = ''
              if (item.label_name == 'direction') {
                lable = 'front'
                value = nc_file_settings.front
              } else if (item.label_name == 'labelMachineName') {
                lable = 'labelMachineName'
                value = nc_file_settings.labelMachineName
              } else if (item.label_name == 'machineCenterName') {
                lable = 'labelMachineName'
                value = nc_file_settings.machineCenterName
              } else {
                lable = item.label_name
              }
              this.fileSetting.push({ lable, value })
            }
          } else {
            this.fileSetting = []
          }
        }
      )
    },
    prefixInteger(num, n) {
      return (Array(n).join(0) + num).slice(-n)
    },
  },
  async created() {
    this.labelImageFormat = this.$store.state.ncSetting.labelImageFormat
      ? this.$store.state.ncSetting.labelImageFormat
      : 'jpg'
    // 正反面条码填充值
    let front_file_identifier =
      this.$store.state.ncSetting.front_file_identifier
    let opposite_file_identifier =
      this.$store.state.ncSetting.opposite_file_identifier
    if (this.$store.state.ncSetting.labelFormat == 'jingmei') {
      await this.getFileSetting()
    }
    await this.getTagList()
    // this.chooseTagTem(0)
    let arrs = JSON.parse(JSON.stringify(this.$store.state.paibanData))
    let len = 0
    let partsIndex = 1
    let copyArrs = JSON.parse(JSON.stringify(arrs))
    // 记录有几个柜子
    let wardrobeNames = {}
    let recordWardrobeNum = 0
    arrs.forEach((item, index) => {
      item.parts = item.parts.filter((v) => v.specialType != 'supplus')
      len += item.parts.length
      item.parts.forEach((e) => {
        e.oriPlankNumF = `${e.plankNum}${opposite_file_identifier}`
        if (front_file_identifier) {
          e.plankNum = `${e.plankNum}${front_file_identifier}`
          e.oriPlankNum = e.plankNum
        }
        let lableImgName = ''
        if (this.$store.state.ncSetting.labelFormat == 'jingmei') {
          lableImgName += `${item.parts[0].address}_`
          this.fileSetting.forEach((set) => {
            if (
              set.lable == 'front' ||
              set.lable == 'labelMachineName' ||
              set.lable == 'machineCenterName'
            ) {
              if (set.value) {
                if (this.img_hasDelimiter) {
                  lableImgName += `${set.value}_`
                } else {
                  lableImgName += `${set.value}`
                }
              }
            } else {
              if (this.img_hasDelimiter) {
                if (set.lable == 'stockNum') {
                  if (String(index + 1).length < this.stockNumLength) {
                    lableImgName += `${this.prefixInteger(
                      index + 1,
                      this.stockNumLength
                    )}_`
                  } else {
                    lableImgName += `${index + 1}_`
                  }
                } else {
                  lableImgName += `${e[set.lable]}_`
                }
              } else {
                if (set.lable == 'stockNum') {
                  if (String(index + 1).length < this.stockNumLength) {
                    lableImgName += `${this.prefixInteger(
                      index + 1,
                      this.stockNumLength
                    )}`
                  } else {
                    lableImgName += `${index + 1}`
                  }
                } else {
                  lableImgName += `${e[set.lable]}`
                }
              }
            }
          })
        }
        lableImgName += e.plankNum
        e.lableImgName = lableImgName
        e.partsIndex = partsIndex
        // if(wardrobeNames.indexOf(e.loc) == -1){
        //     wardrobeNames.push(e.loc)
        // }
        if (wardrobeNames.hasOwnProperty(`${e.loc}_${e.roomID}`)) {
          e.wardrobeNum = wardrobeNames[`${e.loc}_${e.roomID}`]
        } else {
          recordWardrobeNum += 1
          wardrobeNames[`${e.loc}_${e.roomID}`] = recordWardrobeNum
          e.wardrobeNum = wardrobeNames[`${e.loc}_${e.roomID}`]
        }
        partsIndex += 1
      })
    })
    this.wardrobeNum = 1
    this.allPage = len
    this.delayLoadingData(arrs)
    let lastParts = arrs[arrs.length - 1].parts
    this.recordLastTag = lastParts[lastParts.length - 1].plankNum
    this.tagItemArrCopy = JSON.parse(JSON.stringify(arrs))
    // 生成余料标签数据
    let newLaveList = []
    for (let i = 0; i < copyArrs.length; i++) {
      let supplus = copyArrs[i].parts.filter((sup) => {
        return sup.specialType == 'supplus'
      })
      if (supplus.length) {
        for (let j = 0; j < supplus.length; j++) {
          let supplusValue = this.cancelLavelList([supplus[j]], copyArrs[i])
          newLaveList.push(supplusValue)
        }
      } else {
        let supplusValue = this.cancelLavelList(copyArrs[i].parts, copyArrs[i])
        newLaveList.push(supplusValue)
      }
    }

    this.laveList = newLaveList
    this.upTagItemArr = countTagDataByDir(2, arrs)
    this.downTagItemArr = countTagDataByDir(3, arrs)
  },
}
</script>

<style lang="less">
.print_tag_wapper {
  min-height: calc(100vh - 60px);
  background-color: #eeeeee;
  .com_content {
    display: flex;
    width: 100%;

    .tag_setTem_wap {
      width: 280px;
      height: calc(100vh - 183px);
      overflow-x: hidden;
      background-color: #fff;

      > div {
        padding: 16px 0px 0px 16px;
        .title {
          display: flex;
          align-items: center;
          color: rgba(0, 0, 0, 0.9);
          font-weight: bold;
          font-size: 14px;
        }
      }
      .tag_tem_wap {
        height: 250px;
        border-bottom: 1px solid #e7e7e7;
        .tag_tem_list {
          max-height: 140px;
          overflow-x: hidden;
          > div {
            height: 34px;
            padding-left: 42px;
            line-height: 34px;
            &.active {
              position: relative;
              color: rgba(0, 0, 0, 0.9);
            }
          }
        }
        .edit_addTem {
          margin-top: 20px;
        }
      }
    }
    .tag_canvas_wap {
      position: relative;
      display: flex;
      flex: 1;
      flex-flow: column;
      align-items: center;
      justify-content: space-between;
      max-height: calc(100vh - 172px);
      padding: 10px 0;
      overflow-y: auto;
    }
    .tag_print_box {
      position: relative;
      width: 256px;
      height: 252px;
      background-color: #d9d9d9;
      border: 1px solid rgba(255, 255, 255, 0.9);
      .print_btn {
        position: absolute;
        bottom: 20px;
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        width: 100%;
        height: 140px;
        padding: 0 30px;
      }
    }
  }
  .ant-radio-group {
    margin-right: 0px;
  }
}
</style>
