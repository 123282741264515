<template>
  <div class="tagTempLave_wapper" style="margin-bottom: 20px">
    <canvas
      class="exportCanvas"
      :id="plankIndex + 'printTagLave' + index"
      :labelName="cancleName"
    ></canvas>
  </div>
</template>

<script>
import JsBarcode from 'jsbarcode'
import QRCode from 'qrcode'

import { getDeviceXDPI, mmToPx } from '../../util/exportFuncs'

// matCode
const textOption = [
  {
    att: '',
    type: 'FixedText',
    name: '材质：',
    x: 12,
    y: 12,
    fontSize: 9,
    fontWeight: '',
  },
  {
    att: 'matCode',
    type: 'DataSource',
    name: '',
    x: 37,
    y: 12,
    fontSize: 9,
    fontWeight: '',
  },

  {
    att: '',
    type: 'FixedText',
    name: '颜色：',
    x: 12,
    y: 27,
    fontSize: 9,
    fontWeight: '',
  },
  {
    att: 'texture',
    type: 'DataSource',
    name: '',
    x: 37,
    y: 27,
    fontSize: 9,
    fontWeight: '',
  },
  {
    att: '',
    type: 'FixedText',
    name: '板件大小：',
    x: 12,
    y: 42,
    fontSize: 9,
    fontWeight: '',
  },
  {
    att: 'plankSize',
    type: 'DataSource',
    name: '',
    x: 57,
    y: 42,
    fontSize: 9,
    fontWeight: '',
  },

  {
    att: '',
    type: 'FixedText',
    name: '板件厚度：',
    x: 12,
    y: 57,
    fontSize: 9,
    fontWeight: '',
  },
  {
    att: 'thick',
    type: 'DataSource',
    name: '',
    x: 57,
    y: 57,
    fontSize: 9,
    fontWeight: '',
  },
]
export default {
  data() {
    return {
      scale: 0.1,
      info: {},
      mycanvas: null,
      currentPlank: null,
      arrPI: [],
      plankScaleXY: 0,
      xyReverse: false,
    }
  },
  props: {
    tagData: {
      type: Object,
      default: {},
    },
    index: {
      // 哪一种小板
      type: Number,
      default: 0,
    },
    canvasColor: {
      type: String,
      default: '#fff',
    },
    plankIndex: {
      // 第几张大板
      type: Number,
      default: 1,
    },
    canvasScaleXY: {
      type: Number,
      default: 1,
    },
    // 小板总数
    allPage: {
      type: Number,
      default: 1,
    },
    nowTagTem: {
      type: Object,
      default: null,
    },
  },
  mounted() {
    this.xyReverse = this.$store.state.ncSetting
      ? this.$store.state.ncSetting.xyReverse
      : false
    this.arrPI = getDeviceXDPI()
    this.info = JSON.parse(JSON.stringify(this.tagData))
    this.draw()
  },
  computed: {
    cancleName() {
      return new Date().getTime() + this.plankIndex
    },
  },
  methods: {
    scaleXY(value) {
      return this.scale * value
    },
    draw() {
      let info = this.info
      let mmCanvasWidth
      let mmCanvasHeight
      if (this.nowTagTem) {
        mmCanvasWidth = this.nowTagTem.tag_width
        mmCanvasHeight = this.nowTagTem.tag_height
      } else {
        mmCanvasWidth = 60
        mmCanvasHeight = 40
      }
      this.plankScaleXY =
        (mmToPx(mmCanvasHeight, this.arrPI) * 2) /
        3 /
        (this.xyReverse ? this.info.plankWidth : this.info.plankHeight)
      this.mycanvas = new fabric.StaticCanvas(
        `${this.plankIndex}printTagLave${this.index}`,
        {
          width: mmToPx(mmCanvasWidth, this.arrPI) * this.canvasScaleXY,
          height: mmToPx(mmCanvasHeight, this.arrPI) * this.canvasScaleXY,
          backgroundColor: this.canvasColor,
        }
      )
      textOption.forEach((item, index) => {
        let addTem
        if (item.type == 'FixedText') {
          addTem = new fabric.Text(`${item.name}`, {
            top: item.y * this.canvasScaleXY,
            left: item.x * this.canvasScaleXY,
            fontWeight: item.fontWeight,
            fontSize: item.fontSize * this.canvasScaleXY,
            selectable: false,
          })
        } else if (item.type == 'DataSource') {
          addTem = new fabric.Text(`${info[item.att] ? info[item.att] : ''}`, {
            top: item.y * this.canvasScaleXY,
            left: item.x * this.canvasScaleXY,
            fontWeight: item.fontWeight,
            fontSize: item.fontSize * this.canvasScaleXY,
            selectable: false,
          })
        }
        this.mycanvas.add(addTem)
      })
      // 获取余料ggid
      let hasCustomize = this.info.parts.filter(
        (v) => v.specialType == 'supplus'
      ).length
      let palnk_data = []
      if (hasCustomize) {
        let part = this.info.parts[0]
        let payloadItem = {
          plankWidth: this.info.plankWidth,
          plankHeight: this.info.plankHeight,
          roomName: this.info.roomName,
          rect: part.rect,
          startX: part.startX,
          startY: part.startY,
        }
        payloadItem.path = part.surplusPath
        palnk_data.push(payloadItem)
      } else {
        this.info.parts.forEach((item) => {
          let payloadItem = {
            plankWidth: this.info.plankWidth,
            plankHeight: this.info.plankHeight,
            roomName: this.info.roomName,
            rect: item.rect,
            startX: item.startX,
            startY: item.startY,
          }
          if (item.path && item.path.length) {
            payloadItem.path = item.path
          }
          palnk_data.push(payloadItem)
        })
      }
      // 暂时注释，后端接口未上线
      // let payLoad = {
      //     palnk_data,
      //     type:this.info.matCode,
      //     width:0,
      //     long:0,
      //     min_width:0,
      //     min_long:0,
      //     thick:this.info.thick,
      //     color:this.info.texture,
      //     name:'',
      //     uid:this.$store.state.userInfo.id,
      //     shape:1
      // }
      // this.$token('/create_surplus_info',payLoad,res => {
      //     if(res.status == 1){
      //         QRCode.toDataURL(`http://eggi.cn/${res.data.ggid}`,{
      //             height: 60 * this.canvasScaleXY,
      //             width: 60 * this.canvasScaleXY,
      //             margin:0,
      //             quality:1,
      //             format: "jpeg",
      //         }).then((url) => {
      //             fabric.Image.fromURL(url, (img) => {
      //                 img.set({
      //                     top:74 * this.canvasScaleXY,
      //                     left:12 * this.canvasScaleXY,
      //                     width:60 * this.canvasScaleXY,
      //                     height:60 * this.canvasScaleXY,
      //                     scaleX:0.7,
      //                     scaleY:0.7,
      //                     selectable:false
      //                 })
      //                 this.mycanvas.add(img)
      //                 this.mycanvas.renderAll()
      //             })
      //         })
      //     }
      // })
      // 裁剪的余料暂时不显示二维码
      if (!hasCustomize) {
        QRCode.toDataURL(`http://eggi.cn/?ggid=${this.info.parts[0].ggid}`, {
          height: 60 * this.canvasScaleXY,
          width: 60 * this.canvasScaleXY,
          margin: 0,
          quality: 1,
          format: 'jpeg',
        }).then((url) => {
          fabric.Image.fromURL(url, (img) => {
            img.set({
              top: 74 * this.canvasScaleXY,
              left: 12 * this.canvasScaleXY,
              width: 60 * this.canvasScaleXY,
              height: 60 * this.canvasScaleXY,
              scaleX: 0.7,
              scaleY: 0.7,
              selectable: false,
            })
            this.mycanvas.add(img)
            this.mycanvas.renderAll()
          })
        })
      }

      let laveText = new fabric.Text('微信扫一扫，余料即入库', {
        fontSize: 9 * this.canvasScaleXY,
        left: 12 * this.canvasScaleXY,
        top: 124 * this.canvasScaleXY,
        fontSize: 8 * this.canvasScaleXY,
      })
      let circle = new fabric.Circle({
        top: 80 * this.canvasScaleXY,
        left: 70 * this.canvasScaleXY,
        radius: 35, //圆形半径
        fill: '#fff', //填充的颜色
        stroke: '#000', // 边框颜色
        strokeWidth: 1, // 边框大小
      })
      let circleText = new fabric.Text('余', {
        top: 92.5 * this.canvasScaleXY,
        left: 82.5 * this.canvasScaleXY,
        fontSize: 10 * this.canvasScaleXY,
        fontWeight: 'bold',
        fill: '#000',
      })
      this.mycanvas.add(circle)
      this.mycanvas.add(circleText)
      this.mycanvas.add(laveText)
      let oTop = 12
      let oLeft =
        mmToPx(mmCanvasWidth, this.arrPI) -
        (this.xyReverse ? this.info.plankHeight : this.info.plankWidth) *
          this.plankScaleXY -
        12

      let parts = []

      if (hasCustomize) {
        parts = this.info.parts.filter((v) => v.specialType == 'supplus')
      } else {
        parts = this.info.parts
      }
      let partsArrs = []
      if (hasCustomize) {
        parts.forEach((item, index) => {
          if (item.path) {
            let pathItem = item.path[0]
            let pointArr = pathItem.map((item) => {
              return {
                x: (item.x * this.plankScaleXY + oLeft) * this.canvasScaleXY,
                y: (item.y * this.plankScaleXY + oTop) * this.canvasScaleXY,
              }
            })
            let difShape = new fabric.Polygon(pointArr, {
              top:
                (item.startY * this.plankScaleXY + oTop) * this.canvasScaleXY,
              left:
                (item.startX * this.plankScaleXY + oLeft) * this.canvasScaleXY,
              stroke: '#000',
              strokeWidth: 1,
              selectable: false,
              fill: '#fff',
            })
            partsArrs.push(difShape)
            // this.mycanvas.add(difShape)
          } else {
            let itemRect = new fabric.Rect({
              width: item.rect.width * this.plankScaleXY * this.canvasScaleXY,
              height: item.rect.height * this.plankScaleXY * this.canvasScaleXY,
              top:
                (item.startY * this.plankScaleXY + oTop) * this.canvasScaleXY,
              left:
                (item.startX * this.plankScaleXY + oLeft) * this.canvasScaleXY,
              stroke: '#fff',
              strokeWidth: 0.1 * this.canvasScaleXY,
              fill: '#fff',
              selectable: false,
            })
            partsArrs.push(itemRect)
            // this.mycanvas.add(itemRect)
          }
        })
      } else {
        parts.forEach((item, index) => {
          if (item.path) {
            let pathItem = item.path[0]
            let pointArr = pathItem.map((item) => {
              return {
                x: (item.x * this.plankScaleXY + oLeft) * this.canvasScaleXY,
                y: (item.y * this.plankScaleXY + oTop) * this.canvasScaleXY,
              }
            })
            let difShape = new fabric.Polygon(pointArr, {
              top:
                (item.startY * this.plankScaleXY + oTop) * this.canvasScaleXY,
              left:
                (item.startX * this.plankScaleXY + oLeft) * this.canvasScaleXY,
              // strokeWidth: 0.1 * this.canvasScaleXY,
              selectable: false,
              fill: '#000',
            })
            partsArrs.push(difShape)
            // this.mycanvas.add(difShape)
          } else {
            let itemRect = new fabric.Rect({
              width: item.rect.width * this.plankScaleXY * this.canvasScaleXY,
              height: item.rect.height * this.plankScaleXY * this.canvasScaleXY,
              top:
                (item.startY * this.plankScaleXY + oTop) * this.canvasScaleXY,
              left:
                (item.startX * this.plankScaleXY + oLeft) * this.canvasScaleXY,
              stroke: '#000',
              // strokeWidth:0.1 * this.canvasScaleXY,
              fill: '#000',
              selectable: false,
            })
            partsArrs.push(itemRect)
            // this.mycanvas.add(itemRect)
          }
        })
      }
      let rect = new fabric.Rect({
        width: this.info.plankWidth * this.plankScaleXY * this.canvasScaleXY,
        height: this.info.plankHeight * this.plankScaleXY * this.canvasScaleXY,
        top: oTop * this.canvasScaleXY,
        left: oLeft * this.canvasScaleXY,
        selectable: false,
        stroke: '#000',
        strokeWidth: hasCustomize ? 0.1 : 1,
        fill: hasCustomize ? '#000' : '#fff',
      })
      let groupTop = oTop
      let groupLeft = oLeft
      if (this.xyReverse) {
        groupTop =
          oTop +
          (Math.abs(this.info.plankHeight - this.info.plankWidth) *
            this.plankScaleXY) /
            2
        groupLeft =
          oLeft -
          (Math.abs(this.info.plankHeight - this.info.plankWidth) *
            this.plankScaleXY) /
            2
      }
      let group = new fabric.Group([rect].concat(partsArrs), {
        width: this.info.plankWidth * this.plankScaleXY * this.canvasScaleXY,
        height: this.info.plankHeight * this.plankScaleXY * this.canvasScaleXY,
        top: groupTop * this.canvasScaleXY,
        left: groupLeft * this.canvasScaleXY,
      })
      if (this.xyReverse) {
        group.rotate(-90)
      }
      this.mycanvas.add(group)
      group.sendToBack()
      let PlankIndexT = oTop + rect.height + 22
      let PlankIndexL = oLeft * this.canvasScaleXY
      if (this.xyReverse) {
        PlankIndexT = oTop * this.canvasScaleXY + group.width + 22
        PlankIndexL =
          (mmToPx(mmCanvasWidth, this.arrPI) -
            this.info.plankHeight * this.plankScaleXY -
            12) *
          this.canvasScaleXY
      }
      let PlankIndex = new fabric.Text(`第${this.plankIndex}张大板`, {
        fontSize: 9 * this.canvasScaleXY,
        left: PlankIndexL,
        top: PlankIndexT,
      })
      this.mycanvas.add(PlankIndex)
      this.mycanvas.renderAll()
    },
  },
  watch: {
    nowTagTem: {
      deep: true,
      handler: function (newV, oldV) {
        this.draw()
      },
    },
  },
}
</script>

<style lang="less" scoped>
.tagTempLave_wapper {
  border-radius: 4px;
  // overflow: hidden;
  #qrCode {
    position: absolute;
    z-index: -1000;
  }
}
</style>
