<template>
  <div class="tagTemp_wapper" style="margin-bottom: 20px">
    <canvas
      class="exportCanvas"
      :id="plankIndex + 'printTag' + index"
      :labelName="currentPlank ? currentPlank.lableImgName : 1"
    ></canvas>
  </div>
</template>

<script>
import { judgePtpNeedBackNum } from '@/util/createTag'
import JsBarcode from 'jsbarcode'
import QRCode from 'qrcode'

// matCode
import { change_plank_dir } from '../../util/countPlankDir'
import { getDeviceXDPI, mmToPx } from '../../util/exportFuncs'

const textOption = [
  {
    att: '',
    type: 'FixedText',
    name: '客户：',
    x: 5,
    y: 5,
    fontSize: 10,
    fontWeight: '',
  },
  {
    att: 'customer_name',
    type: 'DataSource',
    name: '',
    x: 30,
    y: 5,
    fontSize: 10,
    fontWeight: '',
  },

  {
    att: '',
    type: 'FixedText',
    name: '订单：',
    x: 5,
    y: 20,
    fontSize: 10,
    fontWeight: '',
  },
  {
    att: 'orderNo',
    type: 'DataSource',
    name: '',
    x: 30,
    y: 20,
    fontSize: 10,
    fontWeight: '',
  },
  // {
  //     att:"plankID",
  //     type:'DataSource',
  //     name:"",
  //     x:5,
  //     y:35,
  //     fontSize:10,
  //     fontWeight:''
  // },
  // {
  //     att:"",
  //     type:'FixedText',
  //     name:"号：",
  //     x:15,
  //     y:35,
  //     fontSize:10,
  //     fontWeight:''
  // },
  // {
  //     att:"partName",
  //     type:'DataSource',
  //     name:"",
  //     x:30,
  //     y:35,
  //     fontSize:10,
  //     fontWeight:''
  // },

  {
    att: '',
    type: 'FixedText',
    name: '房间：',
    x: 5,
    y: 35,
    fontSize: 10,
    fontWeight: '',
  },
  {
    att: 'roomName',
    type: 'DataSource',
    name: '',
    x: 30,
    y: 35,
    fontSize: 10,
    fontWeight: '',
  },

  {
    att: '',
    type: 'FixedText',
    name: '柜名：',
    x: 5,
    y: 50,
    fontSize: 10,
    fontWeight: '',
  },
  {
    att: 'loc',
    type: 'DataSource',
    name: '',
    x: 30,
    y: 50,
    fontSize: 10,
    fontWeight: '',
  },

  {
    att: '',
    type: 'FixedText',
    name: '成品尺寸：',
    x: 5,
    y: 65,
    fontSize: 10,
    fontWeight: '',
  },
  {
    att: 'oSize',
    type: 'DataSource',
    name: '',
    x: 50,
    y: 65,
    fontSize: 10,
    fontWeight: '',
  },
  {
    att: '',
    type: 'FixedText',
    name: '材质：',
    x: 5,
    y: 80,
    fontSize: 10,
    fontWeight: '',
  },
  {
    att: 'matCode_texture',
    type: 'DataSource',
    name: '',
    x: 30,
    y: 80,
    fontSize: 10,
    fontWeight: '',
  },
  {
    att: '',
    type: 'FixedText',
    name: '查看安装图',
    x: 5,
    y: 140,
    fontSize: 8,
    fontWeight: '',
  },
]
export default {
  data() {
    return {
      scale: 0.1,
      info: {},
      mycanvas: null,
      currentPlank: null,
      arrPI: [],
      plankScaleXY: 0,
      savePlank: [],
      recordCodeF: [],
      recordCodeFAll: [],
      edgeInfo: [],
      SpinNum: 0, //五六面钻基准  0：左下角；1：左上角；2：右上角；3：右下角
      ncSetting: null,
      xyReverse: false,
    }
  },
  props: {
    // 柜体数量
    wardrobeNum: {
      type: Number,
      default: 0,
    },
    tagData: {
      type: Object,
      default: {},
    },
    // 哪一张小板
    index: {
      type: Number,
      default: 0,
    },
    // 标签背景色
    canvasColor: {
      type: String,
      default: '#fff',
    },
    // 第几张大板
    plankIndex: {
      type: Number,
      default: 1,
    },
    // 放大倍数
    canvasScaleXY: {
      type: Number,
      default: 1,
    },
    // 小板总数
    allPage: {
      type: Number,
      default: 1,
    },
    // 当前标签模板数据
    nowTagTem: {
      type: Object,
      default: null,
    },
    // 镜像
    jxChecked: {
      type: Boolean,
      default: false,
    },
    // 排序适应上下料
    sortValue: {
      type: Number,
      default: 1,
    },
    // 反面条码
    plankNumFed: {
      type: Boolean,
      default: false,
    },
    fiveSixDir: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    // 测试用
    this.xyReverse = this.$store.state.ncSetting
      ? this.$store.state.ncSetting.xyReverse
      : false
    this.arrPI = getDeviceXDPI()
    this.info = JSON.parse(JSON.stringify(this.tagData))
    this.currentPlank = this.info.parts[this.index]
    this.edgeInfo = this.countEdgeInfoByJXType(false)
    this.handleSpecialData()
    if (this.nowTagTem) {
      this.drawTem()
    } else {
      // 弃用，默认标签也从外部传入标签布局数据
      this.plankScaleXY = 105 / this.info.plankHeight
      this.draw()
    }
  },
  computed: {},
  methods: {
    getRotateNum(plank) {
      let output56FDrillSlotDir = 'default'
      let output56FDrillLayoutDir = 'Vertical'
      let ncSetting = this.$store.state.ncSetting
      if (
        ncSetting &&
        ncSetting.hasOwnProperty('output56FDrillSlotDir') &&
        ncSetting.hasOwnProperty('output56FDrillLayoutDir')
      ) {
        output56FDrillSlotDir = ncSetting.output56FDrillSlotDir
        output56FDrillLayoutDir = ncSetting.output56FDrillLayoutDir
      }
      this.SpinNum = change_plank_dir(
        plank,
        {
          ...ncSetting,
          output56FDrillSlotDir,
          output56FDrillLayoutDir,
          panelSize: ncSetting.panelSize,
          xyReverse: this.xyReverse,
        },
        this.info.plankWidth,
        this.info.plankHeight
      )
    },
    drawFiveSixDir() {
      let items = this.mycanvas
        .getObjects()
        .filter((e) => e.temName == 'spinDir')
      if (items.length) {
        this.mycanvas.remove(items[0])
      }
      let spinNum = this.SpinNum
      let path = []
      // 获取标签宽高
      let width =
        mmToPx(this.nowTagTem ? this.nowTagTem.tag_width : 60, this.arrPI) *
        this.canvasScaleXY
      let height =
        mmToPx(this.nowTagTem ? this.nowTagTem.tag_height : 40, this.arrPI) *
        this.canvasScaleXY
      let dirPath = null
      let min = 10
      let max = 40
      let mid = 20
      let top = 0
      let left = 0
      switch (spinNum) {
        case 0:
          path = [
            { x: 0, y: height - max },
            { x: min, y: height - max },
            { x: min, y: height - min },
            { x: max, y: height - min },
            { x: max, y: height },
            { x: 0, y: height },
            { x: 0, y: height - max },
          ]
          top = height - max
          left = 0
          break
        case 1:
          path = [
            { x: 0, y: 0 },
            { x: 0, y: max },
            { x: min, y: max },
            { x: min, y: min },
            { x: max, y: min },
            { x: max, y: 0 },
            { x: 0, y: 0 },
          ]
          top = 0
          left = 0
          break
        case 2:
          path = [
            { x: width - max, y: 0 },
            { x: width, y: 0 },
            { x: width, y: max },
            { x: width - min, y: max },
            { x: width - min, y: min },
            { x: width - max, y: min },
            { x: width - max, y: 0 },
          ]
          top = 0
          left = width - max
          break
        case 3:
          path = [
            { x: width - min, y: height - max },
            { x: width, y: height - max },
            { x: width, y: height },
            { x: width - max, y: height },
            { x: width - max, y: height - min },
            { x: width - min, y: height - min },
            { x: width - min, y: height - max },
          ]
          top = height - max
          left = width - max
          break
        default:
          path = [
            { x: 0, y: 0 },
            { x: 0, y: max },
            { x: max, y: max },
            { x: max, y: mid },
            { x: min, y: mid },
            { x: min, y: 0 },
            { x: 0, y: 0 },
          ]
          top = 0
          left = 0
          break
      }
      dirPath = new fabric.Polygon(path, {
        top: top,
        left: left,
        fill: '#000',
        selectable: false,
        opacity: this.fiveSixDir ? 1 : 0,
        temName: 'spinDir',
      })
      this.mycanvas.add(dirPath)
    },
    handleSpecialData() {
      let currentPlank = JSON.parse(JSON.stringify(this.currentPlank))
      // 成品尺寸，先取出字段组合
      this.currentPlank.oSize =
        currentPlank.oRect.height +
        '*' +
        currentPlank.oRect.width +
        '*' +
        currentPlank.thick
      // 切割尺寸
      this.currentPlank.cutSize =
        (
          Number(currentPlank.oRect.height) -
          Number(this.edgeInfo[0]) -
          Number(this.edgeInfo[2])
        ).toFixed(2) +
        '*' +
        (
          Number(currentPlank.oRect.width) -
          Number(this.edgeInfo[1]) -
          Number(this.edgeInfo[3])
        ).toFixed(2) +
        '*' +
        currentPlank.thick
      // 成品尺寸 + 材质
      this.currentPlank.matCode_texture =
        currentPlank.matCode + '-' + currentPlank.texture
      this.currentPlank.handle_name = currentPlank.handle
        ? currentPlank.handle.partsChildren &&
          currentPlank.handle.partsChildren.length
          ? currentPlank.handle.partsChildren[0].maskPartName ||
            currentPlank.handle.partsChildren[0].matSpec
            ? currentPlank.handle.partsChildren[0].maskPartName ||
              currentPlank.handle.partsChildren[0].matSpec +
                '_' +
                currentPlank.handle.partsChildren[0].name
            : ''
          : ''
        : ''
      if (Array.isArray(this.currentPlank.partsChildren)) {
        let partsChildren = this.currentPlank.partsChildren
        for (let i = 0; i < partsChildren.length; i++) {
          if (partsChildren[i].name.indexOf('铰链') != -1) {
            this.currentPlank.hinge_label =
              partsChildren[i].maskPartName || partsChildren[i].matSpec
                ? partsChildren[i].maskPartName || partsChildren[i].matSpec
                : ''
          } else if (partsChildren[i].name.indexOf('拉直器') != -1) {
            this.currentPlank.straightener_label =
              partsChildren[i].maskPartName || partsChildren[i].matSpec
                ? partsChildren[i].maskPartName || partsChildren[i].matSpec
                : ''
          }
        }
      }
    },
    scaleXY(value) {
      return this.scale * value
    },
    draw() {
      let info = this.currentPlank
      this.mycanvas = new fabric.StaticCanvas(
        `${this.plankIndex}printTag${this.index}`,
        {
          width: mmToPx(60, this.arrPI) * this.canvasScaleXY,
          height: mmToPx(40, this.arrPI) * this.canvasScaleXY,
          backgroundColor: this.canvasColor,
        }
      )
      // this.mycanvas.setZoom(this.canvasScaleXY)
      textOption.forEach((item, index) => {
        let addTem
        if (item.type == 'FixedText') {
          addTem = new fabric.Text(`${item.name}`, {
            top: item.y * this.canvasScaleXY,
            left: item.x * this.canvasScaleXY,
            fontWeight: item.fontWeight,
            fontSize: item.fontSize * this.canvasScaleXY,
            selectable: false,
          })
        } else if (item.type == 'DataSource') {
          addTem = new fabric.Text(`${info[item.att] ? info[item.att] : ''}`, {
            top: item.y * this.canvasScaleXY,
            left: item.x * this.canvasScaleXY,
            fontWeight: item.fontWeight,
            fontSize: item.fontSize * this.canvasScaleXY,
            selectable: false,
          })
        }

        this.mycanvas.add(addTem)
      })
      if (this.currentPlank.ggid) {
        QRCode.toDataURL(`${this.currentPlank.ggid}`, {
          height: 60 * this.canvasScaleXY,
          width: 60 * this.canvasScaleXY,
          margin: 0,
          quality: 1,
          format: 'jpeg',
        }).then((url) => {
          fabric.Image.fromURL(url, (img) => {
            img.set({
              top: 95 * this.canvasScaleXY,
              left: 5 * this.canvasScaleXY,
              width: 60 * this.canvasScaleXY,
              height: 60 * this.canvasScaleXY,
              scaleX: 0.7,
              scaleY: 0.7,
              selectable: false,
            })
            this.mycanvas.add(img)
            this.mycanvas.renderAll()
          })
        })
      }

      let holes = this.currentPlank.holes.filter((item) => {
        if (item.holeType == 'bigHole' || item.holeType == 'preHole') {
          return item
        }
      })
      let frontH = holes.filter((item) => {
        return item.side == 1
      }).length
      let backH = holes.filter((item) => {
        return item.side == -1
      }).length
      let sholesL = this.currentPlank.sholes.length
      if (frontH || sholesL || sessionStorage.getItem('thinkerx_material')) {
        let oneCode = this.textToBase64Barcode(this.currentPlank.plankNum)
        if (oneCode) {
          fabric.Image.fromURL(oneCode, (img) => {
            img.set({
              top: 95 * this.canvasScaleXY,
              left: 50 * this.canvasScaleXY,
              height: 60 * this.canvasScaleXY,
              // scaleY:0.8,
              // scaleX:0.8,
              selectable: false,
            })
            this.mycanvas.add(img)
            this.mycanvas.renderAll()
          })
        }
      }

      // 排版图
      let oTop = 10
      let oLeft = 160
      let rect = new fabric.Rect({
        width: this.info.plankWidth * this.plankScaleXY * this.canvasScaleXY,
        height: this.info.plankHeight * this.plankScaleXY * this.canvasScaleXY,
        top: oTop * this.canvasScaleXY,
        left: oLeft * this.canvasScaleXY,
        selectable: false,
        stroke: '#000',
        strokeWidth: 1,
        fill: '#fff',
      })
      this.drawDefaultParts(this.info.parts, oTop, oLeft)
      this.mycanvas.add(rect)
      rect.sendToBack()
      // 左下右上

      // 大板序号、页码
      let PlankO = new fabric.Text(`第${this.plankIndex}张大板`, {
        fontSize: 10 * this.canvasScaleXY,
        top: 125 * this.canvasScaleXY,
        left: 170 * this.canvasScaleXY,
        selectable: false,
      })
      let tagPage = new fabric.Text(
        `第${this.currentPlank.partsIndex}/${this.allPage}页`,
        {
          fontSize: 8 * this.canvasScaleXY,
          top: 140 * this.canvasScaleXY,
          left: 175 * this.canvasScaleXY,
          selectable: false,
        }
      )
      this.mycanvas.add(PlankO)
      this.mycanvas.add(tagPage)
      this.mycanvas.renderAll()
      if (this.$listeners['callBack']) {
        this.$emit('callBack', this.currentPlank.plankNum)
      }
    },
    textToBase64Barcode(text) {
      let canvas = document.createElement('canvas')
      let base64Data = ''
      try {
        JsBarcode(canvas, text, {
          format: 'EAN13',
          width: 1.0 * this.canvasScaleXY,
          height: 40 * this.canvasScaleXY,
          margin: 0,
          fontSize: 14 * this.canvasScaleXY,
        })
        base64Data = canvas.toDataURL('image/png', {
          quality: 1,
          format: 'jpeg',
        })
      } catch (e) {
        base64Data = ''
      }
      return base64Data
    },
    countSourceData(type) {
      let req
      if (type) {
        switch (type) {
          case 'plank_index':
            req = `第${this.plankIndex}张大板`
            break
          case 'lable_index':
            req = `第${this.currentPlank.partsIndex}/${this.allPage}页`
            break
          case 'plankID':
            req = `${this.currentPlank[type]}#`
            break
          default:
            req = this.currentPlank[type] ? this.currentPlank[type] : ''
            break
        }
        return String(req)
      } else {
        return ''
      }
    },
    drawTem() {
      let temData = this.nowTagTem
      let items = temData.tem_data
      let rectL = temData.rectInfo.left
      let rectT = temData.rectInfo.top
      this.mycanvas = new fabric.StaticCanvas(
        `${this.plankIndex}printTag${this.index}`,
        {
          width: mmToPx(temData.tag_width, this.arrPI) * this.canvasScaleXY,
          height: mmToPx(temData.tag_height, this.arrPI) * this.canvasScaleXY,
          backgroundColor: this.canvasColor,
        }
      )
      // this.drawFiveSixDir()
      // 正反面孔
      let holes = this.currentPlank.holes
      // 正反面槽
      let solts = this.currentPlank.slots
      // 正面孔
      let frontH = holes.filter((item) => {
        return item.side == 1
      }).length
      // 背面孔
      let backH = holes.filter((item) => {
        return item.side == -1
      }).length
      // 侧孔
      let sholesL = this.currentPlank.sholes.length
      // 正面槽
      let frontSlots = this.currentPlank.slots.filter((item) => {
        return item.side == 1
      }).length
      // 背面槽
      let Bslots = this.currentPlank.slots.filter((item) => {
        return item.side == -1
      }).length
      // 侧槽
      let sSlots = this.currentPlank.sslots.length
      items.forEach((item) => {
        let info = item.data
        switch (item.type) {
          case 'FixedText':
            let Text = new fabric.Textbox(info.text, {
              fontSize: info.fontSize * this.canvasScaleXY,
              selectable: false,
              fontWeight: info.fontWeight,
              lineHeight: info.lineHeight,
              left: (info.left - rectL) * this.canvasScaleXY,
              top: (info.top - rectT) * this.canvasScaleXY,

              // 自动换行
              splitByGrapheme: info.splitByGrapheme
                ? info.splitByGrapheme
                : false,
              textAlign: info.textAlign ? info.textAlign : 'left',
            })
            if (info.width && info.height) {
              Text.set({
                width: info.width * this.canvasScaleXY,
                height: info.height * this.canvasScaleXY,
              })
            }
            this.mycanvas.add(Text)
            break
          case 'Graphics':
            let points = info.points
            let newPoints = points.map((e) => {
              return {
                x: (e.x - rectL) * this.canvasScaleXY,
                y: (e.y - rectT) * this.canvasScaleXY,
              }
            })
            let Polygon = new fabric.Polygon(newPoints, {
              top: (info.top - rectT) * this.canvasScaleXY,
              left: (info.left - rectL) * this.canvasScaleXY,
              fill: info.fill,
              scaleX: info.scaleX,
              scaleY: info.scaleY,
              angle: info.angle,
              originX: 'center',
              originY: 'center',
              selectable: false,
            })
            this.mycanvas.add(Polygon)
            break
          case 'DataSource':
            let value = this.countSourceData(info.source_data)
            if (value) {
              let Itext = new fabric.Text(value, {
                fontSize: info.fontSize * this.canvasScaleXY,
                selectable: false,
                fontWeight: info.fontWeight,
                left: (info.left - rectL) * this.canvasScaleXY,
                top: (info.top - rectT) * this.canvasScaleXY,
              })
              this.mycanvas.add(Itext)
            }

            break
          case 'QRcode':
            let url
            // 有反面标签
            if (
              info.source_data == 'plankNum' ||
              info.source_data == 'ggid' ||
              info.source_data == 'oriPlankNum' ||
              info.source_data == 'remarks' ||
              info.source_data == 'remark'
            ) {
              if (info.source_data == 'plankNum') {
                if (frontH || sholesL || frontSlots) {
                  url = this.currentPlank[info.source_data]
                }
              } else if (info.source_data == 'oriPlankNum') {
                if (info.showCode) {
                  url = this.currentPlank[info.source_data]
                } else {
                  if (
                    frontH ||
                    backH ||
                    sholesL ||
                    (solts && solts.length) ||
                    sSlots
                  ) {
                    url = this.currentPlank.oriPlankNum
                  }
                }
              } else {
                url = this.currentPlank[info.source_data]
              }
              if (url) {
                QRCode.toDataURL(url, {
                  height: mmToPx(info.mHeight, this.arrPI) * this.canvasScaleXY,
                  width: mmToPx(info.mWidth, this.arrPI) * this.canvasScaleXY,
                  margin: 0,
                  quality: 1,
                  format: 'jpeg',
                }).then((url) => {
                  fabric.Image.fromURL(url, (img) => {
                    img.set({
                      top: (info.top - rectT) * this.canvasScaleXY,
                      left: (info.left - rectL) * this.canvasScaleXY,
                      width:
                        mmToPx(info.mWidth, this.arrPI) * this.canvasScaleXY,
                      height:
                        mmToPx(info.mHeight, this.arrPI) * this.canvasScaleXY,

                      selectable: false,
                    })
                    this.mycanvas.add(img)
                  })
                })
              }
            } else if (info.source_data == 'plankNumF') {
              let isF = false

              if (backH || Bslots) {
                isF = true
                url = this.currentPlank.oriPlankNumF
              }
              // if(this.currentPlank.oriPlankNumF && frontH && backH){
              //     isF = true
              //     url = this.currentPlank.oriPlankNumF
              // }else if(frontH || backH){
              //     url = this.currentPlank.plankNum + 'K'
              // }
              if (url) {
                QRCode.toDataURL(url, {
                  height: mmToPx(info.mHeight, this.arrPI) * this.canvasScaleXY,
                  width: mmToPx(info.mWidth, this.arrPI) * this.canvasScaleXY,
                  margin: 0,
                  quality: 1,
                  format: 'jpeg',
                }).then((url) => {
                  fabric.Image.fromURL(url, (img) => {
                    img.set({
                      top: (info.top - rectT) * this.canvasScaleXY,
                      left: (info.left - rectL) * this.canvasScaleXY,
                      width:
                        mmToPx(info.mWidth, this.arrPI) * this.canvasScaleXY,
                      height:
                        mmToPx(info.mHeight, this.arrPI) * this.canvasScaleXY,

                      selectable: false,
                    })
                    if (info.source_data == 'plankNumF') {
                      this.recordCodeFAll.push(img)
                      if (this.plankNumFed) {
                        this.mycanvas.add(img)
                        if (isF) {
                          this.recordCodeF.push(img)
                        }
                      } else {
                        if (isF) {
                          this.mycanvas.add(img)
                          this.recordCodeF.push(img)
                        }
                      }
                    } else {
                      this.mycanvas.add(img)
                    }
                  })
                })
              }
            } else {
              return
            }
            break
          case 'Onecode':
            let isF = false
            let OneCodeUrl
            let Ocanvas = document.createElement('canvas')
            if (info.source_data == 'plankNum') {
              let plankNumV
              if (frontH || sholesL || frontSlots) {
                plankNumV = this.currentPlank.plankNum
              }
              try {
                JsBarcode(Ocanvas, plankNumV, {
                  format: info.code_type.toUpperCase(),
                  width: 1.0 * this.canvasScaleXY,
                  height: (mmToPx(10, this.arrPI) / 3) * 2 * this.canvasScaleXY,
                  margin: 0,
                  fontOptions: info.fontWeight
                    ? info.fontWeight == 'bold'
                      ? 'bold'
                      : ''
                    : '',
                  fontSize: info.fontSize * this.canvasScaleXY,
                  textMargin: info.lineHeight,
                })
                OneCodeUrl = Ocanvas.toDataURL('image/png', {
                  quality: 1,
                  format: 'jpeg',
                })
              } catch (e) {
                OneCodeUrl = ''
              }
            } else if (info.source_data == 'plankNumF') {
              let plankNumV
              if (backH || Bslots || judgePtpNeedBackNum(sSlots, sholesL)) {
                isF = true
                plankNumV = this.currentPlank.oriPlankNumF
              }
              // if(this.currentPlank.oriPlankNumF && frontH && backH){
              //     isF = true
              //     plankNumV = this.currentPlank.oriPlankNumF
              // }else if(frontH || backH){
              //     plankNumV = this.currentPlank.plankNum + 'K'
              // }
              JsBarcode(Ocanvas, plankNumV, {
                format: info.code_type.toUpperCase(),
                width: 1.0 * this.canvasScaleXY,
                height: (mmToPx(10, this.arrPI) / 3) * 2 * this.canvasScaleXY,
                margin: 0,
                fontOptions: info.fontWeight
                  ? info.fontWeight == 'bold'
                    ? 'bold'
                    : ''
                  : '',
                fontSize: info.fontSize * this.canvasScaleXY,
                textMargin: info.lineHeight,
              })
              OneCodeUrl = Ocanvas.toDataURL('image/png', {
                quality: 1,
                format: 'jpeg',
              })
            } else if (info.source_data == 'oriPlankNum') {
              let plankNumV
              if (info.showCode) {
                plankNumV = this.currentPlank.oriPlankNum
              } else {
                // 只有有孔槽才显示条码
                if (
                  frontH ||
                  backH ||
                  sholesL ||
                  (solts && solts.length) ||
                  sSlots
                ) {
                  plankNumV = this.currentPlank.oriPlankNum
                } else {
                  return
                }
              }
              JsBarcode(Ocanvas, plankNumV, {
                format: info.code_type.toUpperCase(),
                width: 1.0 * this.canvasScaleXY,
                height: (mmToPx(10, this.arrPI) / 3) * 2 * this.canvasScaleXY,
                margin: 0,
                fontOptions: info.fontWeight
                  ? info.fontWeight == 'bold'
                    ? 'bold'
                    : ''
                  : '',
                fontSize: info.fontSize * this.canvasScaleXY,
                textMargin: info.lineHeight,
              })
              OneCodeUrl = Ocanvas.toDataURL('image/png', {
                quality: 1,
                format: 'jpeg',
              })
            } else if (
              info.source_data == 'remarks' ||
              info.source_data == 'remark'
            ) {
              try {
                JsBarcode(Ocanvas, this.currentPlank[info.source_data], {
                  format: info.code_type.toUpperCase(),
                  width: 1.0 * this.canvasScaleXY,
                  height: (mmToPx(10, this.arrPI) / 3) * 2 * this.canvasScaleXY,
                  margin: 0,
                  fontOptions: info.fontWeight
                    ? info.fontWeight == 'bold'
                      ? 'bold'
                      : ''
                    : '',
                  fontSize: info.fontSize * this.canvasScaleXY,
                  textMargin: info.lineHeight,
                })
                OneCodeUrl = Ocanvas.toDataURL('image/png', {
                  quality: 1,
                  format: 'jpeg',
                })
              } catch (e) {
                OneCodeUrl = ''
                console.error(e)
              }
            } else {
              return
            }
            if (OneCodeUrl) {
              fabric.Image.fromURL(OneCodeUrl, (img) => {
                img.set({
                  width: mmToPx(info.mWidth, this.arrPI) * this.canvasScaleXY,
                  top: (info.top - rectT) * this.canvasScaleXY,
                  left: (info.left - rectL) * this.canvasScaleXY,
                  height: mmToPx(info.mHeight, this.arrPI) * this.canvasScaleXY,
                  selectable: false,
                })
                if (info.source_data == 'plankNumF') {
                  this.recordCodeFAll.push(img)
                  if (this.plankNumFed) {
                    this.mycanvas.add(img)
                    if (isF) {
                      this.recordCodeF.push(img)
                    }
                  } else {
                    if (isF) {
                      this.mycanvas.add(img)
                      this.recordCodeF.push(img)
                    }
                  }
                } else {
                  this.mycanvas.add(img)
                }
                this.mycanvas.renderAll()
              })
            }

            break
          case 'Typography':
            this.drawEditParts(info, rectL, rectT)
            break
          case 'wardrobeNum':
            let radius = info.radius * this.canvasScaleXY
            let circle = new fabric.Circle({
              radius: radius, //圆形半径
              fill: '#fff', //填充的颜色
              stroke: '#000', // 边框颜色
              strokeWidth: 1, // 边框大小
            })
            let numText = new fabric.IText(
              String(this.currentPlank.wardrobeNum),
              {
                fontSize: info.fontSize * this.canvasScaleXY,
                fontWeight: info.fontWeight,
                originX: 'cneter',
                originY: 'cneter',
                left: radius,
                top: radius,
              }
            )
            let cirGroup = new fabric.Group([circle, numText], {
              left: (info.left - rectL) * this.canvasScaleXY,
              top: (info.top - rectT) * this.canvasScaleXY,
            })
            this.mycanvas.add(cirGroup)
            break
        }
      })
      this.mycanvas.renderAll()
      if (this.$listeners['callBack']) {
        this.$emit('callBack', this.currentPlank.plankNum)
      }
    },
    getEdgeValue(name, edgeInfo) {
      switch (name) {
        case 'LE':
          return String(edgeInfo[0])
          break
        case 'BE':
          return String(edgeInfo[1])
          break
        case 'RE':
          return String(edgeInfo[2])
          break
        case 'TE':
          return String(edgeInfo[3])
          break
      }
    },
    createCanvas() {
      let doc = document.getElementById(
        `${this.plankIndex}printTag${this.index}`
      )
      let parent = doc.parentNode
      if (parent.className == 'canvas-container') {
        parent = doc.parentNode.parentNode
      }
      while (parent.firstChild) {
        parent.removeChild(parent.firstChild)
      }
      let newC = document.createElement('canvas')
      newC.className = 'exportCanvas'
      newC.id = `${this.plankIndex}printTag${this.index}`
      newC.setAttribute(
        'labelName',
        this.currentPlank ? this.currentPlank.plankNum : 1
      )
      parent.append(newC)
      this.mycanvas = null
    },
    drawDefaultJX(type) {
      let oTop = 10
      let oLeft = 160
      let parts = this.info.parts
      this.savePlank.forEach((item) => {
        this.mycanvas.remove(item)
      })
      this.savePlank = []
      // this.mycanvas.renderAll()
      parts.forEach((item) => {
        item.startX =
          this.currentPlank.plankWidth - item.startX - item.rect.width
      })
      this.drawDefaultParts(parts, oTop, oLeft)
    },
    drawEditJX(type) {
      let temArrs = this.nowTagTem.tem_data.filter((item) => {
        return item.type == 'Typography'
      })
      if (temArrs.length) {
        let parts = this.info.parts
        this.savePlank.forEach((item) => {
          this.mycanvas.remove(item)
        })
        this.savePlank = []
        parts.forEach((item) => {
          if (this.xyReverse) {
            item.startY = this.info.plankHeight - item.startY - item.rect.height
            if (item.path && item.path.length) {
              let path = item.path[0]
              let oPath = item.oPath[0]
              for (let i = 0; i < path.length; i++) {
                path[i].y = this.info.plankHeight - path[i].y
              }
              for (let i = 0; i < oPath.length; i++) {
                oPath[i].y = Math.abs(item.oRect.height - oPath[i].y)
              }
            }
          } else {
            item.startX = this.info.plankWidth - item.startX - item.rect.width
            if (item.path && item.path.length) {
              let path = item.path[0]
              let oPath = item.oPath[0]
              for (let i = 0; i < path.length; i++) {
                path[i].x = this.info.plankWidth - path[i].x
              }
              for (let i = 0; i < oPath.length; i++) {
                oPath[i].x = Math.abs(item.oRect.width - oPath[i].x)
              }
            }
          }
          // 镜像处理槽
          let slots = item.slots
          if (slots && slots.length) {
            for (let i = 0; i < slots.length; i++) {
              let pt1 = slots[i].pt1
              let pt2 = slots[i].pt2
              // 竖向
              if (pt1.x == pt2.x) {
                pt1.x = Math.abs(item.oRect.width - pt1.x)
                pt2.x = pt1.x
              }
              if (pt1.y == pt2.y) {
                pt1.y = Math.abs(item.oRect.height - pt1.y)
                pt2.y = pt1.y
              }
            }
          }
          // 镜像处理斜封边拉手
          let handleSlopes = item.handleSlopes
          if (handleSlopes && handleSlopes.length) {
            for (let i = 0; i < handleSlopes.length; i++) {
              let pt1 = handleSlopes[i].pt1
              let pt2 = handleSlopes[i].pt2
              // 竖向
              if (pt1.x == pt2.x) {
                pt1.x = Math.abs(item.oRect.width - pt1.x)
                pt2.x = pt1.x
              }
              if (pt1.y == pt2.y) {
                pt1.y = Math.abs(item.oRect.height - pt1.y)
                pt2.y = pt1.y
              }
            }
          }
        })
        let rectL = this.nowTagTem.rectInfo.left
        let rectT = this.nowTagTem.rectInfo.top
        temArrs.forEach((item) => {
          let info = item.data
          this.drawEditParts(info, rectL, rectT)
        })
      }
    },
    countEdgeInfoByJXType(type) {
      let edgeInfo = this.currentPlank.edgeInfo
        .split(/\←|\↓|\→|\↑/)
        .filter((v) => v)
      if (type) {
        if (this.xyReverse) {
          let copyEdgeV = JSON.parse(JSON.stringify(edgeInfo))[1]
          edgeInfo[1] = edgeInfo[3]
          edgeInfo[3] = copyEdgeV
        } else {
          let copyEdgeV = JSON.parse(JSON.stringify(edgeInfo))[0]
          edgeInfo[0] = edgeInfo[2]
          edgeInfo[2] = copyEdgeV
        }
      }
      return edgeInfo
    },
    drawDefaultParts(parts, oTop, oLeft) {
      // 切换镜像交换左右封边值
      let edgeInfo = this.countEdgeInfoByJXType(this.jxChecked)
      let partsArrs = []
      let savePlank = []
      parts.forEach((item, index) => {
        if (item.path) {
          item.path.forEach((pathItem, i) => {
            if (i == 0) {
              let pointArr = pathItem.map((item) => {
                return {
                  x: (item.x * this.plankScaleXY + oLeft) * this.canvasScaleXY,
                  y: (item.y * this.plankScaleXY + oTop) * this.canvasScaleXY,
                }
              })
              let difShape = new fabric.Polygon(pointArr, {
                top:
                  (item.startY * this.plankScaleXY + oTop) * this.canvasScaleXY,
                left:
                  (item.startX * this.plankScaleXY + oLeft) *
                  this.canvasScaleXY,
                stroke: '#000',
                strokeWidth: 0.5 * this.canvasScaleXY,
                selectable: false,
                fill: '#fff',
              })
              if (index == this.index) {
                difShape.set({
                  fill: '#000',
                })
              }
              savePlank.push(difShape)
              partsArrs.push(difShape)
              this.mycanvas.add(difShape)
            } else {
              let xs = []
              let ys = []
              let pointArr = pathItem.map((e) => {
                xs.push(e.x)
                ys.push(e.y)
                return {
                  x: (e.x * this.plankScaleXY + oLeft) * this.canvasScaleXY,
                  y: (e.y * this.plankScaleXY + oTop) * this.canvasScaleXY,
                }
              })
              let top =
                Math.min.apply(null, ys) * this.plankScaleXY +
                (parts[index].startY * this.plankScaleXY + oTop)
              let left =
                Math.min.apply(null, xs) * this.plankScaleXY +
                (parts[index].startX * this.plankScaleXY + oLeft)
              let difShape = new fabric.Polygon(pointArr, {
                top: top * this.canvasScaleXY,
                left: left * this.canvasScaleXY,
                stroke: '#000',
                strokeWidth: 0.5 * this.canvasScaleXY,
                selectable: false,
                fill: '#fff',
              })
              savePlank.push(difShape)
              partsArrs.push(difShape)
              this.mycanvas.add(difShape)
            }
          })
        } else {
          let itemRect = new fabric.Rect({
            width: item.rect.width * this.plankScaleXY * this.canvasScaleXY,
            height: item.rect.height * this.plankScaleXY * this.canvasScaleXY,
            top: (item.startY * this.plankScaleXY + oTop) * this.canvasScaleXY,
            left:
              (item.startX * this.plankScaleXY + oLeft) * this.canvasScaleXY,
            stroke: '#000',
            strokeWidth: 0.5 * this.canvasScaleXY,
            fill: '#fff',
            selectable: false,
          })
          if (index == this.index) {
            itemRect.set({
              fill: '#000',
            })
          }
          savePlank.push(itemRect)
          partsArrs.push(itemRect)
          this.mycanvas.add(itemRect)
        }
      })
      let arr = [
        {
          value: edgeInfo[0],
          set: {
            top:
              (oTop + (this.info.plankHeight * this.plankScaleXY) / 2) *
              this.canvasScaleXY,
            left: (oLeft - 5) * this.canvasScaleXY,
          },
        },
        {
          value: edgeInfo[1],
          set: {
            top:
              (oTop + this.info.plankHeight * this.plankScaleXY) *
              this.canvasScaleXY,
            left:
              (oLeft + (this.info.plankWidth * this.plankScaleXY) / 2 - 5) *
              this.canvasScaleXY,
          },
        },
        {
          value: edgeInfo[2],
          set: {
            top:
              (oTop + (this.info.plankHeight * this.plankScaleXY) / 2) *
              this.canvasScaleXY,
            left:
              (oLeft + this.info.plankWidth * this.plankScaleXY) *
              this.canvasScaleXY,
          },
        },
        {
          value: edgeInfo[3],
          set: {
            top: (oTop - 5) * this.canvasScaleXY,
            left:
              (oLeft + (this.info.plankWidth * this.plankScaleXY) / 2 - 5) *
              this.canvasScaleXY,
          },
        },
      ]
      arr.forEach((item, index) => {
        let edgeNum = new fabric.Text(item.value, {
          fontSize: 8 * this.canvasScaleXY,
          fontWeight: 'bold',
          selectable: false,
          ...item.set,
        })
        savePlank.push(edgeNum)
        this.mycanvas.add(edgeNum)
      })
      this.getRotateNum(this.info.parts[this.index])
      this.drawFiveSixDir()
      this.savePlank = savePlank
    },
    drawEditParts(info, rectL, rectT) {
      if (!info.mWidth || !info.mHeight) {
        return
      }
      let edgeInfo = this.countEdgeInfoByJXType(this.jxChecked)
      new Promise((resolve) => {
        // 获取文本宽高
        let widthS = []
        let heightS = []
        info.textItems.forEach((e) => {
          let text = new fabric.Text(
            this.getEdgeValue(e.positionName, edgeInfo),
            {
              positionName: e.positionName,
              fontSize: e.fontSize * this.canvasScaleXY,
              fontWeight: e.fontWeight,
            }
          )
          widthS.push(text.width)
          heightS.push(text.height)
        })
        resolve({
          width: Math.max.apply(null, widthS),
          height: Math.max.apply(null, heightS),
        })
      }).then((data) => {
        this.getRotateNum(this.info.parts[this.index])
        this.drawFiveSixDir()
        // 根据文本宽高计算排版图偏移量
        let textHeight = data.height
        let textWidth = data.width
        let gW = mmToPx(info.mWidth, this.arrPI)
        let gH = mmToPx(info.mHeight, this.arrPI)

        let ScaleX = (gW - textWidth) / this.currentPlank.plankWidth
        let ScaleY =
          (gH - textHeight) /
          (this.xyReverse ? this.info.plankWidth : this.info.plankHeight)
        let PlankScale = Math.min.apply(null, [ScaleX, ScaleY])
        // let PlankScale = ScaleY
        let plankW = this.info.plankWidth * PlankScale
        let plankH = this.info.plankHeight * PlankScale
        let rect = new fabric.Rect({
          selectable: false,
          stroke: '#000',
          strokeWidth: 1.5,
          fill: '#fff',
          width: plankW * this.canvasScaleXY,
          height: plankH * this.canvasScaleXY,
          left: textWidth,
          top: textHeight,
        })
        let parts = this.info.parts
        let partsArrs = []
        parts.forEach((item, index) => {
          if (item.path) {
            item.path.forEach((pathItem, i) => {
              if (i == 0) {
                let pointArr = pathItem.map((item) => {
                  return {
                    x: item.x * PlankScale * this.canvasScaleXY + textWidth,
                    y: item.y * PlankScale * this.canvasScaleXY + textHeight,
                  }
                })
                let difShape = new fabric.Polygon(pointArr, {
                  top:
                    item.startY * PlankScale * this.canvasScaleXY + textHeight,
                  left:
                    item.startX * PlankScale * this.canvasScaleXY + textWidth,
                  stroke: '#000',
                  strokeWidth: 0.7 * this.canvasScaleXY,
                  selectable: false,
                  fill: '#fff',
                })
                if (index == this.index) {
                  difShape.set({
                    fill: '#000',
                  })
                }
                partsArrs.push(difShape)
                this.mycanvas.add(difShape)
              } else {
                let xs = []
                let ys = []
                let pointArr = pathItem.map((e) => {
                  xs.push(e.x)
                  ys.push(e.y)
                  return {
                    x: e.x * PlankScale * this.canvasScaleXY + textWidth,
                    y: e.y * PlankScale * this.canvasScaleXY + textHeight,
                  }
                })
                let top =
                  Math.min.apply(null, ys) * PlankScale +
                  (parts[index].startY * PlankScale +
                    textWidth / this.canvasScaleXY)
                let left =
                  Math.min.apply(null, xs) * PlankScale +
                  (parts[index].startX * PlankScale +
                    textHeight / this.canvasScaleXY)
                let difShape = new fabric.Polygon(pointArr, {
                  top: top * this.canvasScaleXY,
                  left: left * this.canvasScaleXY,
                  stroke: '#000',
                  strokeWidth: 0.7 * this.canvasScaleXY,
                  selectable: false,
                  fill: '#fff',
                })
                partsArrs.push(difShape)
                this.mycanvas.add(difShape)
              }
            })
          } else {
            let itemRect = new fabric.Rect({
              width: item.rect.width * PlankScale * this.canvasScaleXY,
              height: item.rect.height * PlankScale * this.canvasScaleXY,
              top: item.startY * PlankScale * this.canvasScaleXY + textHeight,
              left: item.startX * PlankScale * this.canvasScaleXY + textWidth,
              stroke: '#000',
              strokeWidth: 0.7 * this.canvasScaleXY,
              fill: '#fff',
              selectable: false,
            })
            if (index == this.index) {
              itemRect.set({
                fill: '#000',
              })
            }
            partsArrs.push(itemRect)
          }
        })
        let textItems = []
        info.textItems.forEach((e) => {
          let text = new fabric.Text(
            this.getEdgeValue(e.positionName, edgeInfo),
            {
              positionName: e.positionName,
              fontSize: e.fontSize * this.canvasScaleXY,
              fontWeight: e.fontWeight,
            }
          )
          let deviationW = plankW * this.canvasScaleXY + textWidth * 2
          let deviationH = plankH * this.canvasScaleXY + textHeight * 2
          switch (e.positionName) {
            case 'BE':
              text.set({
                top: deviationH - textHeight,
                left: deviationW / 2 - textWidth / 2,
              })
              break
            case 'TE':
              text.set({
                top: e.top,
                left: deviationW / 2 - textWidth / 2,
              })
              break

            case 'RE':
              text.set({
                top: deviationH / 2 - textWidth / 2,
                left: deviationW - textWidth,
              })
              break
            case 'LE':
              text.set({
                top: deviationH / 2 - textWidth / 2,
                left: e.left,
              })
              break
          }
          textItems.push(text)
        })
        let groupTop = 0
        let groupLeft = 0
        if (this.xyReverse) {
          groupTop =
            (info.top - rectT + (plankW - plankH) / 2) * this.canvasScaleXY
          groupLeft =
            (info.left - rectL - (plankW - plankH) / 2) * this.canvasScaleXY
        } else {
          groupTop = (info.top - rectT) * this.canvasScaleXY
          groupLeft = (info.left - rectL) * this.canvasScaleXY
        }
        let Group = new fabric.Group(
          [rect].concat(partsArrs).concat(textItems),
          {
            width: plankW * this.canvasScaleXY + textWidth,
            height: plankH * this.canvasScaleXY + textHeight,
            left: groupLeft,
            top: groupTop,
            // angle: -90,
            // originX: 'center',
            // originY: 'center'
            // selectable:false
          }
        )
        if (this.xyReverse) {
          Group.rotate(-90)
        }
        this.savePlank.push(Group)
        this.mycanvas.add(Group)
      })
    },
  },
  watch: {
    fiveSixDir(value) {
      let items = this.mycanvas
        .getObjects()
        .filter((e) => e.temName == 'spinDir')
      if (items.length) {
        items[0].set({
          opacity: value ? 1 : 0,
        })
      }
      this.mycanvas.renderAll()
    },
    plankNumFed(value) {
      if (value) {
        this.recordCodeF.forEach((item) => {
          this.mycanvas.remove(item)
        })
        this.recordCodeFAll.forEach((item) => {
          this.mycanvas.add(item)
        })
        this.mycanvas.renderAll()
      } else {
        this.recordCodeFAll.forEach((item) => {
          this.mycanvas.remove(item)
        })
        this.recordCodeF.forEach((item) => {
          this.mycanvas.add(item)
        })
      }
      this.mycanvas.renderAll()
    },
    sortValue(value) {
      this.info = JSON.parse(JSON.stringify(this.tagData))
      this.handleSpecialData()
      this.createCanvas()
      if (this.nowTagTem) {
        this.drawTem()
      } else {
        this.draw()
      }
    },
    nowTagTem: {
      deep: true,
      handler: function (newV, oldV) {
        this.recordCodeF = []
        this.recordCodeFAll = []
        if (newV) {
          this.savePlank = []
          this.createCanvas()
          this.drawTem()
        } else {
          this.savePlank = []
          if (!this.plankScaleXY) {
            this.plankScaleXY = 105 / this.info.plankHeight
          }
          this.createCanvas()
          this.draw()
        }
      },
    },
    jxChecked(value) {
      if (!this.nowTagTem) {
        if (value) {
          this.drawDefaultJX(true)
        } else {
          this.drawDefaultJX(false)
        }
      } else {
        if (value) {
          this.drawEditJX(true)
        } else {
          this.drawEditJX(false)
        }
      }
    },
  },
}
</script>

<style lang="less" scoped>
.tagTemp_wapper {
  // border-radius: 4px;
  overflow: hidden;
  #qrCode {
    position: absolute;
    z-index: -800;
  }
}
</style>
